import React from 'react'
import "./Testinomial.css";
import greenLine from "../Images/greenLine.png";

const Testinomial = () => {
  return (
    <div className='test-container-section' id="testimonial-section">
        <div className='testimonial-section'>
            <div className='test-section-1'>
                <div className='test-para'>
                   <p>"The material handling solutions from PRIYAL Industry have significantly improved our operational efficiency. Their attention to detail and dedication to quality are unparalleled. We have been consistently impressed with their innovative systems and exceptional customer support. Highly recommended!"</p>
                </div>
                <div className='test-details'>
                    <div className='test-head'>
                        <h4>Deepak Pawar</h4>
                    </div>
                    <div className='test-subhead'>
                        <span>(Manager) Shahu Industries Pune</span>
                    </div>
                </div>
            </div>
            <div className='test-section-2'>
                <div className='test-para'>
                    <p>"PRIYAL Industry's material handling solutions have transformed our workflow. Their reliable products and excellent service have made a big difference. We're very happy with the results!"</p>
                </div>
                <div className='test-details-2'>
                    <div className='test-head'>
                        <h4>Krushna Jadhav </h4>
                    </div>
                    <div className='test-subhead'>
                        <span>(CEO) Aryan Pumps Indapur</span>
                    </div>
                </div>
            </div>
         </div>
         <div className='testimonial-bottom-img'>
             <img src={greenLine} alt="green line"/>
         </div>
    </div>
  )
}

export default Testinomial