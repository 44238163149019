import React from 'react';
import "./About.css";
import jib from "../Images/jib1.jpg";
import chain from "../Images/jib-crane.jpeg";
import rightIcon from "../Images/whiteRightIcon.svg";
const About = () => {
  return (
    <div className='about-container-section ' id="about-section">
      <div className='about-section'>
        <div className='about-head'>
          <h1>Leading the Industry in Material Handling Eqiupments.</h1>
        </div>
        <div className='about-heading-para'>
           <p>We are dedicated to leading the industry in material handling systems, offering innovative solutions that enhance efficiency and safety. Our commitment to high-quality systems is tailored to meet diverse needs, ensuring exceptional performance and client satisfaction.</p>
        </div>
        <div className='about-contact'>
          <a href="mailto:priyalindustries24@gmail.com">Contact Us <img src={rightIcon} alt="right icon" /></a> 
        </div>
      </div>
      <div className='about-image-section'>
        <div className='about-image'>
          <div className='about-1'>
            <img src={jib} alt="Jib Crane" />
          </div>
        </div>
        <div className='about-small-content'>
          <div className='about-content'>
            <div className='about-small-head'>
                <span>SERVING SINCE 2023</span>
            </div>
            <div className='about-small-description'>
                <h2>Excellence in Material Handling Across Industries</h2>
            </div>
          </div>
          <div className='about-2'>
            <img src={chain} alt="Chain Host" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default About;
