import React, { useState } from 'react';
import './Home.css';
import building from '../Images/buildinglogo.png';
import company from '../Images/logo.png';
import phoneCall from '../Images/phoneCall.png';

const Home = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
    setIsActive(!isActive);
  };

  return (
    <div className='home-container'>
      <div className='home-normal-container'>
        <div className='header-logo-icon'>
          <div className='logo-image'>
            <div className='log-1'>
              <img src={building} alt='building logo' />
            </div>
            <div className='log-2'>
              <img src={company} alt='company logo' />
            </div>
          </div>
        </div>
        <div className='navbar-container'>
          <div className='navbar_normal-contianer'>
            <div className='navbar_links'>
              <nav>
                <ul>
                  <li><a href='/'>Home</a></li>
                  <li><a href='#about-section'>About</a></li>
                  <li><a href='#testimonial-section'>Testimonial</a></li>
                  <li><a href='#services-section'>Services</a></li>
                  <li><a href='#contact-section'>Contact</a></li>
                </ul>
              </nav>
            </div>
            <div className='navbar_number'>
              <a href='tel:9665402810'>
                <div className='phone_icon'>
                  <img src={phoneCall} alt='phone_icon'></img>
                </div>
                <div className='phone_num'>
                  <p>
                    Call us: <a href='tel:9665402810'>9665402810</a>
                  </p>
                </div>
              </a>
            </div>
          </div>
          <div className='navbar-resp-container'>
            <div className={`menu-icon ${isActive ? 'active' : ''}`} onClick={handleMenu}>
              <div className='menu-1'></div>
              <div className='menu-2'></div>
              <div className='menu-3'></div>
            </div>
          </div>
        </div>
      </div>

      <div className='home-resp-container'>
        <div className={`resp-navbar-link ${menuOpen ? 'open' : ''}`}>
          <div className='navbar_links_resp'>
            <nav>
              <ul>
                <li><a href='/'>Home</a></li>
                <li><a href='#about-section'>About</a></li>
                <li><a href='#testimonial-section'>Testimonial</a></li>
                <li><a href='#services-section'>Services</a></li>
                <li><a href='#contact-section'>Contact</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
