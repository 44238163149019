import React from 'react'
import "./Hero.css";
import rightIcon from "../Images/right-icon.svg";
import bgIcon from "../Images/bgLine.png";
import robe from "../Images/ropeHoist.jpg";
import jcrane from "../Images/jib-crane.jpeg";
import overHeadCrane from "../Images/overHeadCranse-1.jpg";
import whiteRightIcon from "../Images/whiteRightIcon.svg";
import whatsapp from "../Images/whatsapp-static.png";
import phoneCall from "../Images/phone-call.png";
const Hero = () => {
  return (
    <div className='hero-container' id="heroSection">
        <div className='hero-section-container'>
             <div className='hero-heading'>
               <h1>PRIYAL INDUSTRIES<span> :</span> Leading in Material Handling Equipments</h1>
             </div>
             <div className='hero-para-heading'>
                 <p>With a growing number of successful installations across the state, our unmatched expertise and commitment to quality speak for themselves.</p>
             </div>
             <div className='right-bottom-link'>
                <div className='bottom-right'>
                    <div className='bottom-whatsapp'>
                        <a href="https://wa.me/9665402810"><img src={whatsapp} alt="whatsapp icon"/></a>
                    </div>
                    <div className='bottom-call'>
                        <a href='tel:9665402810'><img src={phoneCall} alt="call icon"/></a>
                    </div>
                </div>
             </div>
             <div className='three-div-header'>
                 <div className="div-1-products">
                    <div className='hero-product-div'>
                         <h3>Our Services</h3>
                    </div>
                    <div className='hero-prod-details'>
                        <div className='prod-span'>
                             <p>Discover The Services We Offer.</p>
                        </div>
                        <div className='right-arrow-hero'>
                            <a href="#services-section"><img src={rightIcon} alt="right arrrow"></img></a>
                        </div>
                    </div>
                 </div>
                 
                 <div className='div-2-contact'>
                    <div className='hero-contact-div'>
                            <h3>Get in Touch</h3>
                        </div>
                        <div className='hero-contact-details'>
                            <div className='contact-span'>
                                <p>Contact Us For Your Order</p>
                            </div>
                            <div className='right-arrow-hero'>
                                <a href="#contact-section"><img src={whiteRightIcon} alt="right arrrow"></img></a>
                            </div>
                        </div>
                    </div>
                    <div className='div-3-img'>
                        <img src={bgIcon} alt="line-image"/>
                    </div>
             </div>
        </div>
        <div className='images-gallery'>
            <div className='image-div1'>
                 <div className='image-div-g1'>
                    <img src={jcrane} alt=""/>
                 </div>
                 <div className='image-div-g1'>
                    <img src={robe} alt=""/>
                 </div>
             </div>
             <div className='image-div2'>
                <img src={overHeadCrane} alt=""/>
             </div>
        </div>
    </div>
  )
}

export default Hero