import React from 'react'
import "./Mission.css";
import box from "../Images/box.png";
import eotInstall from "../Images/installcranse.jpg";
import jinInstall from "../Images/jibInstall.webp";
const Mission = () => {
  return (
    <div className='mission-container-section'>
         <div className='mission-section'>
             <div className='mission-des'>
                <div className='mission-heading'>
                     <h1>Commitment to Excellence in Every Aspect of Material Handling</h1>
                </div>
                <div className='mission-sub_heading'>
                     <span>Designing, Manufacturing, and Installing with Precision and Integrity</span>
                </div>
                <div className='image-list-section'>
                    <div className='mission-para-p'>
                        <div className='list-box'>
                            <div className='images-box'>
                                <img src={box} alt="image-box"/>
                            </div>
                            <div className='image-points'>
                                <p>Ensure smooth installation and effective integration into client systems.</p>
                            </div>
                        </div>
                        <div className='list-box'>
                            <div className='images-box'>
                                <img src={box} alt="image-box"/>
                            </div>
                            <div className='image-points'>
                                <p>Promote a growth-oriented environment with continual job opportunities.</p>
                            </div>
                        </div>

                        <div className='list-box'>
                            <div className='images-box'>
                                <img src={box} alt="image-box"/>
                            </div>
                            <div className='image-points'>
                                <p>Enhance capabilities to improve customer satisfaction and support their needs.</p>
                            </div>
                        </div>

                        <div className='list-box'>
                            <div className='images-box'>
                                <img src={box} alt="image-box"/>
                            </div>
                            <div className='image-points'>
                                <p>Build strong supplier relationships for mutual business growth and success.</p>
                            </div>
                        </div>

                        <div className='list-box'>
                                <div className='images-box'>
                                    <img src={box} alt="image-box"/>
                                </div>
                                <div className='image-points'>
                                    <p>Employ advanced technology and rigorous quality control for durable equipment.</p>
                                </div>
                        </div>

                    </div>
               
                <div className='mission-images'>
                    <div className='img-2 hide-img-rep'>
                         <img src={jinInstall} alt="image2"/>
                     </div>
                     <div className='img-1'>
                         <img src={eotInstall} alt="image1"/>
                     </div>
                     
                </div>
                </div>
             </div>
         </div>
    </div>
  )
}

export default Mission