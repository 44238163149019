import React from 'react'
import "./Footer.css";
import logo from "../Images/logo.png";
import building from "../Images/buildinglogo.png"
import  whatsapp from "../Images/whatsapp.png";
import facebook from "../Images/facebook.png";
import insta from "../Images/instagram.png"
const Footer = () => {
    const currentYear = new Date().getFullYear();
  return (
    <div className='footer-section-container'>
         <div className='footer-section'>
              <div className='company-footer'>
                 <div className='company-logo-footer'>
                     <div className='building-logo'>
                         <img src={building} alt="Company-logo"/>
                     </div>
                     <div className='company-name'>
                         <img src={logo} alt="company logo"/>   
                     </div>
                 </div>
                 <div className='company-social-link'>
                     <div className='instagram'>
                        <a href="https://www.instagram.com/priyal.industries?igsh=MXc5Ym0zaXM2djh6bA==">
                            <div className='instagram-logo'>
                                <img src={insta} alt="instagram"/>
                            </div>
                            <div className='instagram-name'>
                                <span>Instagram</span>
                            </div>
                        </a>
                     </div>
                     <div className='facebook'>
                         <a href="https://www.facebook.com/share/fDVo9UcbxqdbSxgA/?mibextid=qi2Omg">
                            <div className='facebook-logo'>
                                <img src={facebook} alt="facebook"/>
                            </div>
                            <div className='facebook-name'>
                                <span>Facebook</span>
                            </div>
                         </a>
                     </div>
                     <div className='whatsapp'>
                         <a href="https://wa.me/9665402810">
                            <div className='whatsapp-logo'>
                                <img src={whatsapp} alt="whatsapp"/>
                            </div>
                            <div className='whatsapp-name'>
                                <span>Whatsapp</span>
                            </div>
                         </a>
                     </div>
                 </div>
              </div>
             <div className='footer-links-nav'>
                <div className='footer-links'>
                    <div className='company-name'>
                        <h3>Company</h3>
                    </div>
                    <div className='footer-navLinks'>
                        <nav>
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li><a href="#about-section">About</a></li>
                                    <li><a href="#testimonail-section">Testimonial</a></li>
                                    <li><a href="#services-section">Services</a></li>
                                    <li><a href="#contact-section">Contact</a></li>
                                </ul>
                            </nav>
                    </div>
                </div>
                <div className='footer-services'>
                    <div className='footer-services-name'>
                        <h3>Services</h3>
                    </div>
                    <div className='services-name'>
                        <ul>
                            <li>Rope Hoist</li>
                            <li>EOT Crane</li>
                            <li>Jib Crane</li>
                            <li>Chain Hoist</li>
                            <li>Underslung Crane</li>
                            <li>HOT Crane</li>
                        </ul>
                    </div>
                </div>
              </div>
         </div>
         <div className='footer-copyright'>
             <p>&copy; {currentYear} <span>PRIYAL INDUSTRIES</span>. All Rights Reserved.</p>
         </div>
    </div>
  )
}

export default Footer