import Home from "./Container/Home/Home";
import Hero from "./Container/Hero/Hero";
import About from "./Container/About/About";
import Testinomial from "./Container/Testinomial/Testinomial";
import Services from "./Container/Services/Services";
import Mission from "./Container/Mission/Mission";
import Contact from "./Container/Contact/Contact";
import Footer from "./Container/Footer/Footer";
function App() {
  return (
    <div className="App">
        <div className="Home-div-container">
           <Home/>
        </div>
        <div className="home-hero-div-container">
           <Hero/>
        </div>
        <div className="home-about-div-container">
          <About/>
        </div>
        <div className="testimonial-div-container">
           <Testinomial/>
        </div>
        <div className="services-div-container">
           <Services/>
        </div>
        <div className="mission-div-container"> 
           <Mission/>
        </div>
        <div className="contact-div-container">
           <Contact/>
        </div>
        <div className="footer-div-container">
           <Footer/>
        </div>
    </div>
  );
}

export default App;
