import React from 'react';
import './Contact.css';
import line from "../Images/blackLineShort.png";

const Contact = () => {
  return (
    <div className='contact-container-section' id="contact-section">
      <div className='contact-section'>
        <div className='line-angle'>
             <img src={line} alt="angle"/>
        </div>
        <div className='contact-heading'>
          <h1>Find Our Factory Near You</h1>
        </div>
        
        <div className='contact-details'>
          <div className='contact-grid'>
            <div className='contact-info'>
              <div className='contact-head'>
                <h2>Contact Information</h2>
              </div>
              <div className='contact-para-d'>
                <p><strong>Address:</strong> B19, Sector 12, Bhosari MIDC, Pune - 411026</p>
                <p><strong>Phone:</strong> 9665402810 / 7517248292</p>
                <p><strong>Email:</strong> priyalindustries24@gmail.com</p>
              </div>
            </div>
            
            <div className='contact-additional'>
              <div className='add-contact-detail'>
                <h2>Business Hours</h2>
              </div>
              <div className='add-time-hour'>
                <p><strong>Monday - Friday:</strong> 9:00 AM - 5:00 PM</p>
                <p><strong>Saturday - Sunday:</strong> 8:00 AM - 8:00 PM</p>
              </div>
            </div>

            <div className='contact-map'>
              <div className='contact-map-details'>
                <h2>Find Us on the Map</h2>
              </div>
              <div className='contact-map-loc'>
                <p><a href='https://www.google.com/maps?q=B19+Sector+12+Bhosari+MIDC+Pune+411026
' target='_blank' rel='noopener noreferrer'>View on Google Maps</a></p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
