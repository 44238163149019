import React, { useState } from 'react';
import './Services.css';

import rope from '../Images/rope-host.webp';
import jib from '../Images/jib-crane.jpeg';
import Eot from '../Images/eot.webp';
import underSulung from '../Images/2t-Underslung-Crane.jpg.webp';
import chainHoist from '../Images/chainHost.jpeg';
import hotCrane from '../Images/hotCrane1.jpeg';
import right from "../Images/right.svg";
import rightWhite from "../Images/whiteRightIcon.svg";

const Services = () => {
  // State to control the visibility of all services
  const [showAll, setShowAll] = useState(false);

  // List of services (for easier iteration)
  const services = [
    { name: 'Rope Host', image: rope },
    { name: 'Jib Crane', image: jib },
    { name: 'EOT Crane', image: Eot },
    { name: 'Underslung Crane', image: underSulung },
    { name: 'Chain Hoist', image: chainHoist },
    { name: 'HOT Crane', image: hotCrane }
  ];

  // Function to toggle 'See more' / 'See less'
  const toggleShowMore = () => {
    setShowAll(!showAll);
  };

  return (
    <div className='services-container-section'>
      <div className='services-section'>
        {/* Main Heading */}
        <div className='services-head'>
          <h1>PROVIDING THE BEST SERVICE WITH QUALITY AND INNOVATION.</h1>
        </div>

        {/* Services Gallery */}
        <div className='services-photo-gallery' id="services-section">
          {services.slice(0, showAll ? services.length : 3).map((service, index) => (
            <div className='services-p1' key={index}>
              <div className='service-head-icon'>
                <div className='services-head-g'>
                  <h1>{service.name}</h1> 
                </div>
                <div className='right-icon'>
                  <img src={right} alt="icon" />
                </div>
              </div>
              <div className='services-g'>
                <img src={service.image} alt={service.name} />
              </div>
            </div>
          ))}
        </div>

        <div className='services-desc'>
          <div className='services-para'>
              <p>Our services cater to various industries, focusing on durability, efficiency, and innovation, ensuring quality and reliability.</p>
          </div>
          <div className='link-icon'>
            <div className='service-link'>
              <span onClick={toggleShowMore} style={{ cursor: 'pointer', color: 'inherit' }}>
                {showAll ? 'See less Services' : 'See more Services'}
              </span>
            </div>
            <div className='service-right-icon'>
              <img src={rightWhite} alt="icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
